import { createStore } from 'vuex'

export default createStore({
  state: {
    breadcrumb: {
      oneTitle: "",
      twoTitle: "",
    },
  },
  getters: {
    breadcrumb: state => state.breadcrumb,
  },
  mutations: {
    SET_BREADCRUMB: (state, breadcrumb) => {
      state.breadcrumb = breadcrumb;
    }
  },
  actions: {
    setBreadcrumb({ commit }, breadcrumb) {
      commit('SET_BREADCRUMB', breadcrumb)
    }
  },
  modules: {
  }
})
